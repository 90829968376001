import React from 'react';
import Fade from 'react-reveal/Fade';

function Cart(props) {
    return (
        <div style={{display: "flex", flexWrap:"wrap", flexDirection:"column", justifyContent: "space-between", height:"100%", maxWidth: 400}}>
            <Fade>
                {
                    (props.link)?(
                        <a href={props.link} target="_blank" rel="dofollow">
                            {/* <h1 className='text-dark display-1'>
                                {props.number+". "}
                                <br></br>
                            </h1> */}
                            <h4
                                className='text-dark display-5'
                            >
                                {props.number+". "+props.title}
                            </h4>
                        </a>
                    ):(
                        <h4
                            className='text-dark display-5'
                        >
                            {props.number+". "+props.title}
                        </h4>
                    )
                }
            </Fade>
            <div>
                <Fade bottom>
                    <p style={{textShadow:"0 0 15px #fff"}}>
                        {props.description}
                    </p>
                    <br></br>
                    <p style={{textShadow:"0 0 15px #fff"}}>
                        {
                            (props.link)?(
                                <span>
                                    <b>
                                        Link :
                                    </b>
                                    <a href={props.link} target="_blank" rel="dofollow">
                                        _{props.link}
                                    </a>
                                    <br></br>
                                </span>
                            ):(
                                <></>
                            )
                        }
                        <b>
                            Realisation :
                        </b>
                        _{props.realisation}
                        <br>
                        </br>
                        <b>
                            Context :
                        </b>
                        _{props.context}
                        <br></br>
                        <b>
                            Role :
                        </b>
                        _{props.role}
                    </p>
                </Fade>
            </div>
        </div>
    )
}

export default Cart;