import React, { useState, useEffect } from 'react';

function Thumbnail(props) {
    const [style, setStyle] = useState({});

    useEffect(() => {
        const updateStyle = () => {
            if (window.innerWidth > 1200) {
                setStyle({ filter: "contrast(100%) brightness(1)", maxHeight: "60vh" });
            } else {
                setStyle({ filter: "contrast(35%) brightness(1.6)", maxHeight: "60vh" });
            }
        };

        // Set the initial style
        updateStyle();

        // Update the style on window resize
        window.addEventListener('resize', updateStyle);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateStyle);
    }, []);

    if (props.screen === "desktop") {
        return (
            <div className="d-flex">
                <img className="tiles" src={props.image} style={style} alt="" />
            </div>
        );
    } else {
        return (
            <div className="d-flex justify-content-around">
                <img className="tilesm" src={props.image1} style={{ ...style, marginRight: 10 }} alt="" />
                <img className="tilesm" src={props.image2} style={style} alt="" />
            </div>
        );
    }
}

export default Thumbnail;
