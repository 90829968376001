import React from 'react';
import Cart from './Cart';
import Thumbnail from './Thumbnail';
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'

function C_T(props) {
    return (
        <div className="d-flex wrap align-items-center"
        style={{height: "100%", width:"100%"}}>
            <Fade ssrFadeout>
                <div style={{height: "100%", width:"100%"}}>
                    <div style={{position: "absolute", height: "100%", paddingLeft: "10vw", paddingRight: "10vw", paddingBottom: "7rem", zIndex: 5}}>
                        <Cart
                            number={props.number}
                            title={props.title}
                            description={props.description}
                            realisation={props.realisation}
                            context={props.context}
                            role={props.role}
                            status={props.status}
                            link={props.link}
                        />
                    </div>
                    <div className='align-items-center justify-content-center' style={{height: "100%", width: "100%", display: "flex", position: "absolute", paddingBottom: "7rem"}}>
                        <div style={{zIndex: 0, transform: "translate(25%, 0%)"}}>
                            <Fade right>
                                <Thumbnail
                                    link={props.link}
                                    image={props.image}
                                    image1={props.image1}
                                    image2={props.image2}
                                    title={props.title}
                                    screen={props.screen}
                                />
                            </Fade>
                        </div>
                    </div>
                </div>
            </Fade>
            <Link to={props.next} style={{position: "absolute", bottom: "1rem", right: "1rem"}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width:65, height:65, backgroundColor:"#0005", borderRadius:32.5}}>
                    <span style={{color: "white"}}>
                        Next
                    </span>
                </div>
            </Link>
            <Link to={props.prev} style={{position: "absolute", bottom: "1rem", left: "1rem"}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width:65, height:65, backgroundColor:"#0005", borderRadius:32.5}}>
                    <span style={{color: "white"}}>
                        Prev
                    </span>
                </div>
            </Link>
        </div>
    )
}

export default C_T;